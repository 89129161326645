// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Vinicius Souza - Engineering Manager, Software Engineer, my notes on humans and machines." />
      <h1>Wanna chat? ☕</h1>
      <ul
        style={{
          margin: "30px",
          flexDirection: "column",
        }}
      >
        <li>
          <a
            href="https://www.linkedin.com/in/vinicius-souza-10359228/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a
            href="https://github.com/vinicius33"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
        <li>
          <a
            href="https://www.goodreads.com/user/show/55048280-vinicius-souza"
            target="_blank"
            rel="noopener noreferrer"
          >
            Goodreads
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/vncsza"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
        {/* <li>Alternatively, you can shoot me a message hi[at]vinicius.org</li> */}
      </ul>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
